<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>{{ $store.state.brandInfo.displayName }} in TiviMate app on Fire stick &amp; Fire stick 4k</p>
								<p><br /></p>
								<p>
									<span
										style="
											color: rgb(0, 0, 0);
											font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
											font-size: 13px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>Important! Before you begin, please note we <strong>only recommend using Tivimate app for the LIVE TV CHANNELS</strong>
										<strong>ONLY</strong></span
									>
								</p>
								<p>
									*Movie and TV show lists will <u><strong>NOT</strong></u> display posters in Tivimate
								</p>
								<p>
									<strong>**For Movies and TV Shows (VOD) we <u>highly</u> recommend using the {{ $store.state.brandInfo.appName }} App.</strong>
								</p>
								<p>
									<strong><br /></strong>
								</p>
								<p>
									First install the app via downloader, use this link in the Downloader app:
									<a href="https://troypoint.com/tivi" rel="noreferrer noopener">https://troypoint.com/tivi</a>, once downloaded open TiviMate<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/06/1.jpg`"
										rel="noreferrer noopener"
										><img
											src="../../../assets/images/gHFXvdFmnV1NeqVsu87r0uRuA8l37O9F9g.png?1563855596"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/gHFXvdFmnV1NeqVsu87r0uRuA8l37O9F9g.png?1563855596"
											data-fileid="48002066276"
											data-uniquekey="1563855517802"
											style="width: 480px" /></a
									><br /><br /><a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/06/2.jpg`" rel="noreferrer noopener"
										><img
											src="../../../assets/images/ChfQ8A1WvI-ObdXhVPsgJUtZz5idYV0Kog.png?1563855596"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/ChfQ8A1WvI-ObdXhVPsgJUtZz5idYV0Kog.png?1563855596"
											data-fileid="48002066275"
											data-uniquekey="1563855517802"
											style="width: 483px" /></a
									><br /><br />
								</p>
								<p>Open the Tivimate app and select add playlist button</p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/06/3.jpg`" rel="noreferrer noopener"
										><img
											src="../../../assets/images/AsR1PoZjFH-tWKuPXzOkafSTjBnQbVRV8w.png?1563855591"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/AsR1PoZjFH-tWKuPXzOkafSTjBnQbVRV8w.png?1563855591"
											data-fileid="48002066266"
											data-uniquekey="1563855517802"
											style="width: 465px" /></a
									><br /><br />
								</p>
								<p>
									Select <strong><span style="font-size: 14px">Enter URL</span></strong> and then enter your m3u URL playlist from
									{{ $store.state.brandInfo.displayName }}.
								</p>
								<p>
									<img
										src="../../../assets/images/yDUWdJgqmZpbQSmWn0q44XoaPiFIzc8g4A.png?1600138313"
										style="width: 649px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48058977166"
									/><span style="font-size: 16px"
										><br /><strong
											><span style="color: rgb(184, 49, 47)"><u dir="ltr">Live TV Channels M3U Playlist:</u></span></strong
										></span
									><span style="font-size: 13px"><br /></span
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										><span dir="ltr" style="font-size: 13px"
											>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
											>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
											><strong>/</strong>m3u8/livetv</span
										></a
									><br /><br /><span style="font-size: 13px">Example:<br /><strong>Live TV Channels</strong><br /></span
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										><span style="font-size: 13px">https://</span></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
									>/api/list/<span style="color: rgb(0, 0, 0)"
										><strong
											><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="45272a277c7474052228242c296b262a28"
												>[email&#160;protected]</a
											></strong
										></span
									>/<span style="color: rgb(0, 0, 0)"><strong>ilovetv50</strong></span
									><strong>/</strong>m3u8/livetv
								</p>
								<p><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/06/5.jpg`" rel="noreferrer noopener"
										><img
											src="../../../assets/images/qkCduDYLgZq104W9WgcH-nEDFgk1-WnXJw.png?1563855591"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/qkCduDYLgZq104W9WgcH-nEDFgk1-WnXJw.png?1563855591"
											data-fileid="48002066267"
											data-uniquekey="1563855517802"
											style="width: 666px" /></a
									><br /><br /><strong>Please Note!</strong> You can only add 1 playlist unless you purchase Tivimate premium version.<br /><strong
										>If you are using the free version of Tivimate we recommend adding only the live TV channels M3U playlist</strong
									>
								</p>
								<p>
									<strong>{{ $store.state.brandInfo.displayName }} has (16) different m3u playlists.</strong>
								</p>
								<p>-1 consists of live channels<br />-1 consists of movies video on demand (VOD)</p>
								<p>14 consist of TV shows VOD</p>
								<p>
									You will need to add all 16 m3u playlists with Tivimate premium if you want the full catalog of Live tv channels and video on demand (Movies and
									TV shows).
								</p>
								<p>
									<span
										style="
											color: rgb(0, 0, 0);
											font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
											font-size: 13px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>Reminder, we only recommend using Tivimate app for the LIVE TV CHANNELS <strong>ONLY</strong></span
									>
								</p>
								<p>
									<strong>*The Movie and TV show lists will <u>NOT</u> display posters</strong>
								</p>
								<p>
									<strong>**For Movies and TV Shows (VOD) we <u>highly</u> recommend using {{ $store.state.brandInfo.appName }} App</strong><br /><br />
								</p>
								<p>Add the playlist you want below<br /><br /></p>
								<p>
									Insert the required m3u and replace <span style="font-size: 16px"><strong>YOUR_</strong></span
									><strong>EMAIL/ PASSWORD</strong> with your details<br /><span style="font-size: 16px"><br /></span>
								</p>
								<p>
									<span style="font-size: 16px"><strong>Live TV Channels:</strong><br /></span
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										><span style="font-size: 13px">https://</span></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
									>/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
									>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
									><strong><span style="color: rgb(0, 0, 0)">/</span></strong
									>m3u8/livetv
								</p>
								<p>
									<br /><strong
										><span style="font-size: 18px"><strong>Movies:</strong></span></strong
									><span dir="ltr" style="font-size: 18px">&nbsp;</span
									><span dir="ltr" style="font-size: 13px"
										>(as mentioned above we do <strong>not</strong> recommend using tivimate for movies or tv shows)<br /><a
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
											rel="noreferrer noopener"
										></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/m3u8/movies<br /><br /></span
									><span style="font-size: 18px"><strong dir="ltr">TV Shows:&nbsp;</strong></span
									><span style="font-size: 14px">(as mentioned above we do <strong>not</strong> recommend using tivimate for movies or tv shows)</span
									><span style="font-size: 18px"
										><strong dir="ltr"><br /></strong
									></span>
								</p>
								<p>
									<span style="font-size: 13px"><strong dir="ltr">(sorted by year of release date 1 newest - 14 oldest)</strong></span>
								</p>
								<p>
									<span style="font-size: 13px"
										>You must add all links for full catalog<br /><a
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
											rel="noreferrer"
										></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/1</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/2`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/2</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/3`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list<span style="color: rgb(0, 0, 0)"><strong>/YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/3</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/4`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/4</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/5</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/6`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/6</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/7`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/7</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/8`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/8</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`" rel="noreferrer"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/9&nbsp;</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a data-fr-linked="true" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/10`"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`" rel="noreferrer"
											><span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
											>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span></a
										>/m3u8/tvshows/10</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a data-fr-linked="true" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/11`"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`" rel="noreferrer"
											><span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
											>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span></a
										>/m3u8/tvshows/11</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a data-fr-linked="true" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/11`"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`" rel="noreferrer"
											><span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
											>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span></a
										>/m3u8/tvshows/12</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a data-fr-linked="true" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/11`"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`" rel="noreferrer"
											><span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
											>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span></a
										>/m3u8/tvshows/13</span
									>
								</p>
								<p>
									<span style="font-size: 13px"
										><a data-fr-linked="true" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/11`"></a
										><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
											><span dir="ltr" style="font-size: 13px">{{ $store.state.brandInfo.tvLink }}</span></a
										>/api/list/<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`" rel="noreferrer"
											><span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
											>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span></a
										>/m3u8/tvshows/14</span
									>
								</p>
								<p><br /></p>
								<p>
									After you have entered the M3U playlist select the <strong>next&nbsp;</strong>button<br />You can enter a name for the playlist,
									<strong>{{ $store.state.brandInfo.displayName }} LIVE</strong> (optional) or select the <strong>Next</strong> button (on the right side)<br /><br /><a
										:href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/06/6.jpg`"
										rel="noreferrer noopener"
										><img
											src="../../../assets/images/cXxdQ5K1-2DgCh2bF-VrCCySqz2hLaWcJg.png?1563855592"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/cXxdQ5K1-2DgCh2bF-VrCCySqz2hLaWcJg.png?1563855592"
											data-fileid="48002066269"
											data-uniquekey="1563855517802"
											style="width: 100%" /></a
									><br /><br />
								</p>
								<p><br /></p>
								<p>Select TV guide from the list on the right of the screen<br /><br /></p>
								<p>Select TV guide url<br /><br /></p>
								<p>Enter your Tv guide url from {{ $store.state.brandInfo.displayName }} (EPG)<br /><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/06/7.jpg`" rel="noreferrer noopener"
										><img
											src="../../../assets/images/4dvmU_YhC-5l8RZHPCWq0Hg8zlOxMKId3w.png?1563855593"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/4dvmU_YhC-5l8RZHPCWq0Hg8zlOxMKId3w.png?1563855593"
											data-fileid="48002066272"
											data-uniquekey="1563855517802"
											style="width: 100%" /></a
									><br /><br />
								</p>
								<p>
									<strong><span style="font-size: 18px; color: rgb(184, 49, 47)">TV Guide/EPG URL</span></strong
									><br />Select Lite or Full url.
								</p>
								<p><br /></p>
								<p><strong>Lite version - 48h listing (For old/small devices)</strong></p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										:href="$store.state.brandInfo.epgLink"
										rel="noreferrer noopener"
										target="_top"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a
										:href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a
										href="$store.state.brandInfo.tvGuidLiteLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a
										:href="$store.state.brandInfo.tvGuidLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a :href="$store.state.brandInfo.tvGuidLink">{{ $store.state.brandInfo.tvGuidLink }}</a> (Zip File)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										:href="$store.state.brandInfo.tvGuidLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a :href="$store.state.brandInfo.tvGuidLink">{{ $store.state.brandInfo.tvGuidLink }}</a> (XML file)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br />
								</p>
								<p><strong>Regular version – 7 day listing&nbsp;</strong></p>
								<p>
									<a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`" rel="noreferrer noopener"></a
									><a data-fr-linked="true" :href="$store.state.brandInfo.tvGuidLink"></a><a :href="$store.state.brandInfo.tvGuidLink"></a
									><a :href="$store.state.brandInfo.tvGuidLink">{{ $store.state.brandInfo.tvGuidLink }}</a>
								</p>
								<p><br /></p>
								<p><strong>For Tivimate/Smarters you might need to use instead of the abvove:</strong></p>
								<p><br /></p>
								<p>
									<a dir="ltr" :href="$store.state.brandInfo.tvGuidLiteLink" rel="noreferrer">{{ $store.state.brandInfo.tvGuidLink }}</a>
								</p>
								<p dir="ltr"><br />Your TV guide EPG should now populate on the screen. It may take a few minutes to populate your TV guide.</p>
								<p><br /></p>
								<p>
									In the event that the above EPG links do not work for you please delete Tivimate app completely and then install a fresh version of Tivimate and
									then try again.
								</p>
								<p><br /></p>
								<p>Choose your favorites and begin watching.</p>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'tivimateInstallationInstructions',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
